import styled from 'styled-components';

export const BackButton = styled.button`
  box-shadow:inset 0px 1px 0px 0px #97c4fe;
	background:linear-gradient(to bottom, #3d94f6 5%, #1e62d0 100%);
	background-color:#3d94f6;
	border-radius:6px;
	border:1px solid #337fed;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #1570cd;
  float: right;
  margin-right: 5vw;
}
&:hover {
	background:linear-gradient(to bottom, #1e62d0 5%, #3d94f6 100%);
	background-color:#1e62d0;
}
&:active {
	position:relative;
	top:1px;
}
`;

export const TextContainer = styled.div`
  padding: 40px;
  width: 80%;
  margin-top: 50px;
  padding-left: 4vw;

  @media (max-width: 1024px) {
    padding: 15px;
  }
  @media (max-width: 768px) {
    padding: 0px 0px;
  }
  @media (max-width: 425px) {
    padding: 0px 0px;
  }
`;

export const MainContainer = styled.div`
  height: auto;
  // &.fixed{
  //   margin-top: 350px;
  //   @media (max-width: 768px) {
  //       margin-top: 155px;
  //       }
  //   }
  //   @media (max-width: 768px) {
  //   padding-top: 90px;
  //   }
`;
export const Body = styled.div`
  position: relative;
  margin-top:100px;
  margin-left: 10vw;
  margin-right: 10vw;
`;

export const TitleContainer = styled.div`
  display: block;
  height: auto;
  padding: 20px;
  margin-bottom: 20px;
`;
export const Title = styled.h2`
  background-color: #fff;
  padding: 20px 30px 0 30px;
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

export const Blueline = styled.hr`
  color: #007DC3;
  border: 2px solid;
  opacity: 1;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vh;
`;

export const TABLE = styled.table`
  border: 2px solid black
`;