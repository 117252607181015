import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import NavigatioBar from '../../components/navigationBar/mainMenu/navigationBar';
import Footer from '../../components/footer/footer';
import * as Styled from './pressReleaseTemplateStyles';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../../styles/GlobalStyles';
import { graphql, navigate } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Fevicon from '../../images/fevicon.png';
import '../../styles/style.css';
import { BLOCKS } from '@contentful/rich-text-types';

const PressReleaseTemplate = ({ data, location, pageContext }) => {

  React.useEffect(() => {
    if (!location?.hash && typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [location?.hash]);

  const pressDate = data?.contentfulContent?.header;
  const path = location?.pathname?.split('/')[1];
  const title = data?.contentfulContent?.title;

  const goBack = () => {
    if (typeof window !== 'undefined') {
      window.history.back();
    }
  }

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p style={{ padding: '0px', marginTop: '20px', marginBottom: '30px' }}>{children}</p>;
      },
      [BLOCKS.TABLE]: (node, children) => {
        return <table style={{ border: '1px solid', marginTop: '10px', marginBottom: '10px', padding: '10px' }}>{children}</table>;
      },
      [BLOCKS.TABLE_ROW]: (node, children) => {
          return <tr>{children}</tr>;
      },
      [BLOCKS.TABLE_CELL]: (node, children) => {
          return <td style={{ padding: '10px' }}>{children}</td>;
      },
    },
  };

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href={Fevicon} type="image/png" />
        {data?.allContentfulSolEcolution?.edges[0]?.metadata && (
          <meta
            name="description"
            content={data?.allContentfulSolEcolution?.edges[0]?.metadata}
          />
        )}
        {data?.allContentfulSolEcolution?.edges[0]?.node?.hasIndex === false && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      
      <NavigatioBar activeLink={path} />
    
      <Styled.MainContainer>
        <Styled.Body>

          <Styled.TitleContainer>
            <Styled.Title>
              {title}
            </Styled.Title>

          </Styled.TitleContainer>
          
          <Styled.Blueline/>
          <Styled.BackButton onClick={() => navigate(-1)}>
            Back
          </Styled.BackButton>
          <Styled.TextContainer>
          {documentToReactComponents(
            JSON.parse(data?.contentfulContent?.contentDetails?.raw),
            optionsMainStyle
          )}
          </Styled.TextContainer>
        </Styled.Body>
        <Footer />
      </Styled.MainContainer>
    </>
  );
};

export const query = graphql`
  query pressReleaseQuery($pageId: String!) {
  contentfulContent(id: { eq: $pageId }) {
    __typename
    id
    title
    elementId
    subText
    header
    contentDetails {
			raw
    }
  }
}
`;

export default PressReleaseTemplate;
